.document-item {
    cursor: pointer;
    &--visited {
        cursor: not-allowed;
        color: #ed6724;
    }
}

.file-list {
    position: relative;

    &__show-all {
        color: white;
        text-decoration: underline;
        cursor: pointer;
        margin: 0 0.5rem;
    }
}