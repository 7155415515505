.data-dump {
    margin: 1rem 0;
    font-size: 0.8rem;
    text-align: center;
    
    &__link {
        color: gray;
        text-decoration: underline;
        margin-left: 0.5rem;
    }

    &__link:hover {
        cursor: pointer;
    }
}

// .data-status {
//     visibility: hidden;
// }