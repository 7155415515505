.breadcrumb {
    display: block;
    // background-color: rgb(63, 63, 63);
    color: white;
    height: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    // border-left: 4px solid white;
    z-index: 999;
  
    &__item {
        margin: 0 1rem;
        &--no-link {
            color: lightgray;
        }
    }
    
    &__icon {
      height: 1rem;
      filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(202deg) brightness(104%) contrast(101%); // white
    }
  
    &__link {
      text-decoration: none;
      color: white;
    }
  
    &__link:hover {
      color: #ed6724;
      cursor: pointer;
    }
}

.logo {
  	height: 3rem;
	margin-right: 1rem;
}