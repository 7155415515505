.error-handler {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: white;
    padding: 10rem;

    &__text {
        margin-top: 1rem;
        
        &--bold {
            font-weight: bold;
        }
    }

    &__img {
        width: 30%;
        margin-bottom: 1rem;
    }

    &__button-container {
        margin-top: 1rem;
        width: 30%;
        display: flex;
        justify-content: center;
    }
}