.sandbox {
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
        color: white;
        margin: 1rem;
    }
}