.DesignOptions-viewGroup {
    margin: 1em 0 1em 0;
    padding: 1em;
}

.DesignOptions-viewGroup-checkbox label {
    display: block;
    padding-left: 12px;
    text-indent: -22px;
}

.DesignOptions-viewGroup-checkbox label span {
    margin-left: 10px;
    vertical-align: middle;
}