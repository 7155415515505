.rct-icon-hide {
    display: none !important;
}

.reset-button {
    width: 100%;
    border: 1px solid #ed6724;
    border-radius: 5px;
    background-color: #ed6724;
    color: white;
    height: 2rem;
    font-weight: bold;
    margin-top: 1rem;
    
    &:hover {
        cursor: pointer;
        background-color: bisque;
        border: 1px solid bisque;
        color: gray;
    }
}