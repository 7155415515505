.canvas-overlay{
    position: fixed;
    visibility: visible !important;
    z-index: 10;
}

.markup-tools{
    width:250px;
    position: fixed;
    visibility: visible !important;
    z-index:11;
}

.markup-tools *{
    position: relative;
}