@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@100;300;400;500;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: "Roboto", sans-serif;
}

h1, h2, h3 {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
}