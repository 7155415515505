.quantity-panel-footer button {
    border: none;
    padding:10px 20px;
    font-size: 17px;
    margin: 20px;
    background: #0E612C;
    color: white;
    font-weight: 300;
}
.quantity-panel table{
    font-size: 13px;
    border-collapse: collapse;
    width: calc( 100% + 40px);
    position: relative;
    left:-20px;
    text-align: left;
}

.quantity-panel td, tr{
    padding: 10px;
}

thead{
    text-align:center;
}

.quantity-panel td{
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.quantity-panel td:nth-child(1){
    max-width:150px;
    text-align: left;
}

.quantity-panel td:nth-child(1){
    max-width:150px;
    text-align: left;
}

.quantity-panel tbody tr:nth-child(odd){
    background: rgb(82, 76, 76, 0.3);
}

.loadedModelList{
    max-height:150px;
    overflow-y: scroll;
    background: none;
    /* padding: 5px; */
    margin-bottom: 30px;
}

.loadedModelListTitle{
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
}

.loadedModelListItem{
    font-size: 13px;
    padding:5px;
}

.loadedModelList .loadedModelListItem:nth-child(odd){
    background:rgb(82, 76, 76, 0.3);
}