.controlpanel {
  height: 100vh;
  width: 50px;
  padding: 0;
  color: rgb(211, 211, 211);
  border: none;
}

.panel-title{
  font-size:30px;
  margin-bottom: 0;
}

.panel-desc {
  margin-top: 0;
  color: #c9c9c9;
}

.infopanel {
  width: 350px;
  padding: 20px;
  // height: calc(100% - 40px);
  height: 90%;
  margin-top: 2.7%;
  justify-content: center;
  position: absolute;
  text-align: left;
  z-index: 10;
  color: rgb(242, 242, 242);
  top: 0px;
  left: 48px;
  background: rgba(46, 47, 47, 1);
  overflow: auto;
}

.invisible {
  display: none;
}

.iconMobileWithWhiteBackground{
  background: url(whitebg.png) center center/16px 16px no-repeat !important;
}

.iconWithWhiteBackground{
  background: url(whitebg.png) center center/11px 11px no-repeat !important;
}

.QTYTooltip {
  color:white;
  padding: 5px 20px;
  border-radius: 10px;
  position: absolute;
  visibility: visible !important;
  display: block;
  z-index:999;
  top:0px;
  right:0px;
  font-size: 14px;
  width:400px;
  background: rgba(46, 47, 47, 0.9);

  &__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.QTYTooltip-entry-title {
  font-weight: 500;
}

.QTYTooltip-entry-value {
  font-weight: 300;
}