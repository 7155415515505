.split {
  height: 100vh;
  width: 100%;
  display: flex;
}

.gutter {
  position: relative;
  background-color: var(--black);
}

.gutter::after {
  content: "\21D4";
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 30px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--black);
}
