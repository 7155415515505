.right-side {
    display: flex;
    align-items: center;
    align-content: center;

    &__icon {
        margin-right: 1rem;
        height: 2rem;
        // border: 1px solid gray;
        padding: 0.4rem;
        border-radius: 50%;

        &:hover {
            background-color:gray;
        }
    }
}
