.table-wrapper {
    flex-wrap: wrap;
}

.bottom-message {
    color: gray;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;

    &__link {
        text-decoration: underline;
        color: white;
        
        &:hover {
            cursor: pointer;
        }
    }
}
