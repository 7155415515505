.loading-info {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    // font-size: 1.25rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    &__text {
        margin: 2rem 0;
    }
}