// breakpoints
$tablet-breakpoint: 48rem;
$desktop-breakpoint: 80rem;

// colors
$color-primary-1: #ed6724; // brand color

$color-secondary-1: #f5f5f5; // text color

$color-background-1: #2f2e2e; // dark
