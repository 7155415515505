@use '~styles/partials/fonts' as *;
@use '~styles/partials/mixins' as *;
@use '~styles/partials/variables' as *;

:root {
	--white: #f5f5f5;
	--black: #1b1b1b;
	--theme-grey: #424242;
}

body {
	background: $color-background-1;
	margin: 0;
	padding: 0;
	height: 100%;
}

.no-overflow {
  	overflow: hidden;
}

.overflow {
  	overflow: auto !important;
}

.page {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

.page-loose {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.centered-row {
	display: flex;
	// flex-wrap will cause the 3D viewer to not work (?!)
	// flex-wrap: wrap;
	// flex-direction: column;
	justify-content: center;
	align-items: center;
}

.centered-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loading-text {
	color: white;
	margin-top: 1rem;
}