.selection-half-panel {
  width: 100%;
  text-decoration: none;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: none;
  background-size: 30vw 30vw;
  background-position: 50% 50%;
  /* background-repeat:repeat; */
  text-decoration: none;
  border:4px solid #383838;
}

.selection-button {
  cursor: pointer;
  width: 12.5vw;
  height: 12.5vw;
  font-size: 3vw;
  color: white;
  /* background-color: rgba(34, 34, 34, 0.6); */
  border-radius: 20px;
  border: none;
}

@media only screen and (max-width: 899px) {
  .selection-half-panel{
    background-size: 60vw 60vw;
  }
  .selection-button {
    width: 25vw;
    height: 25vw;
  }
}

/*
.selection-button:hover {
  background-color: rgba(34, 34, 34, 1);
} */