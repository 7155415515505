.adsk-viewing-viewer{background:none!important}

.viewer-wrapper {
  position: relative;
  height: 100vh;
}

.pdf-navbar {
  display: block;
  background-color: rgb(63, 63, 63);
  color: white;
  height: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0.5rem;

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
  }
}

.viewer {
  position: absolute;
  width: 100%;
  height: 95%;
}

.half-viewer {
  width: 50%;
  height: 100%;
}

.half-viewer:nth-child(1) {
  background-color: red;
}

/* REMOVE TOOLBAR ICONS */
.viewer #toolbar-bimWalkTool, #toolbar-zoomTools, #toolbar-levelsTool, #toolbar-modelStructureTool {
  display:none !important;
}

/* -----------------------------------------------------------------------------
For PDF viewer
----------------------------------------------------------------------------- */ 
.doc-placeholder {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &--hidden {
    display: none;
  }

  &__img {
    width: 60%;
    // height: 50%;
  }

  &__text {
    margin-top: 2rem;
    // border: 3px dashed rgb(63, 63, 63);
    color: rgb(63, 63, 63);
    // border-radius: 10px;
    // padding: 2rem;
    text-align: center;
  }
}

.webviewer {
  height: 100%;

  &--hidden {
    display: none;
  }
}

