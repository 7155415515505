.screen-403{
    width:100%;
    height:100%;
    position: fixed;
    top:0;
    left:0;
    padding: 10%;
    background: #f8f8f8;
}

.screen-403-text{
    width: 100%;
    text-align: center;
}

.screen-403-visual{
    background-repeat: no-repeat;
    background-size: 80% 100%;
    height: 100%;
    background-position: 50%;
}