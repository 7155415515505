.horizontal-link {
    margin: 1rem 0;
    width: 100%;
    display: flex;
    
    &__item {
        color: white;
        text-decoration: underline;
        margin-right: 1rem;
        
        &:hover {
            cursor: pointer;
        }
    }
}