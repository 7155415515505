@use '~styles/partials/variables' as *;

.brand-button {
    background-color: $color-primary-1;
    color: $color-secondary-1;
    padding: 0.6rem;
    border: 1px solid $color-primary-1;
    border-radius: 10px;
    display: flex;
    min-width: 7rem;
    justify-content: space-between;
    align-items: flex-start;

    &__text {
        font-size: 1rem;
    }

    &__icon {
        height: 1rem;
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(202deg) brightness(104%) contrast(101%); // white
    }

    &:hover {
        cursor: pointer;
        background-color: chocolate;
        border: 1px solid chocolate;
    }
}