@use '~styles/partials/variables' as *;

// for video
.root {
    width: 100%;
    height: 100vh;
    position: relative;
    // prevent scrolling
    margin: 0;
    overflow: hidden;
}

.root video {
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
