.modal-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 24, 44, 0.9);
    z-index: 999;

    &__main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__message {
        margin: 2rem;
    }

    &--display-block {
        display: block;
    }

    &--display-none {
        display: none;
    }    
}