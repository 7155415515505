@use '~styles/partials/variables' as *;

.datadump {
    // display: flex;
    // flex-direction: column;
    margin: 1rem auto;
    // justify-content: space-around;
    width: 80%;

    &__button-container {
        width: 30%;
    }

    &__table {
        margin: 1rem auto;
        color: white;

        &-header {
            display: flex;
            justify-content: space-between;
            font-weight: bold;
        }

        &-body {
            display: flex;
            flex-direction: column;
            margin: 0.4rem 0;
        }

        &-row {
            display: flex;
            justify-content: space-between;
            margin: 0.4rem 0;
        }

        &-col-1 {
            width: 30%;;
        }

        &-col-2 {
            width: 50%;
        }

        &-col-3 {
            width: 20%;
        }
    }
}