.sidebar {
  width: 50px;
  height: 100%;
  position: fixed;
  z-index: 9;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(63, 63, 63);
  border-right: 1px solid rgb(63, 63, 63);

  &__link {
    height: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__home {
    height: 50%;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(202deg) brightness(104%) contrast(101%); // white
    padding: auto;
  }

  &__home:hover {
    filter: invert(50%) sepia(65%) saturate(2853%) hue-rotate(347deg) brightness(97%) contrast(92%); // BIM color
  }

  &__icons {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.fas-icon-wrapper {
  height: 24px;
  width: 24px;
  color: white;
  padding: 13px;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  display: block;
}

.fas-icon-wrapper svg{
  /* padding-top: 15px; */
  margin: auto;
}

.fas-icon-wrapper:hover {
  background-color: rgb(36, 36, 36);
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
