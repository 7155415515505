.loading {
    display: flex;
    flex-direction: column;

    &__text {
        color: white;
        font-size: 1.2rem;
    }

    &__icon-container {
        height: 5rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
    }

    &__icon {
        filter: invert(46%) sepia(65%) saturate(1675%) hue-rotate(346deg) brightness(96%) contrast(94%); // brand color
        height: 100%;
    }
}