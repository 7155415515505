.search {
    display: flex;
    flex-direction: column;
    width: 100%;    

    &__bar {
        border: 1px solid gray;
        font-size: 1.1rem;
        border-radius: 5px;
        height: 1.5rem;
        padding-left: 0.4rem;
    };

    &__bar:focus {
        outline-width: 0;
        background-color: bisque;
    }

    &__status {
        font-size: 0.8rem;
        color: red;
        font-style: italic;
        visibility: hidden;
    }

    &__status--show {
        visibility: visible;
    }
}